

import React, { Component } from 'react';
import ReactDOM from 'react-dom';

import Routes from './routes'

import './App.css';
import 'whatwg-fetch'

class App extends Component {
  constructor(props) {
    super(props);
    this.googleMapsCallback = this.googleMapsCallback.bind(this);
    this.enableScrollIntoView = this.enableScrollIntoView.bind(this);

    window.googleMapsCallback = this.googleMapsCallback;

    this.state = {
      _googleMapsLoaded: false,
      _enableScrollIntoView: null,
      pages: [{},{},{},{}],
      reviews: [],
      data: {}
    };

  }


  enableScrollIntoView(enableScrollIntoView, callback) {
    this.setState({
      _enableScrollIntoView: enableScrollIntoView
    }, callback)
  }

  render() {

    return (
      <Routes
        {...this.state}
        enableScrollIntoView={this.enableScrollIntoView}
      />

    );

  }


  componentDidMount() {
    fetch('https://api.aimedia.ch/pages', {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
    }).then((res) => res.json()).then((result) => {
      //console.log(result);
      this.setState({
        pages: result
      })
    })
    fetch('https://api.aimedia.ch/reviews', {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
    }).then((res) => res.json()).then((result) => {
      //console.log(result);
      this.setState({
        reviews: result
      })
    })

    fetch('https://api.aimedia.ch/data', {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
    }).then((res) => res.json()).then((result) => {
      //console.log(result);
      this.setState({
        data: result
      })
    })

  }

  googleMapsCallback() {
    this.setState({ _googleMapsLoaded: true });
  }

}

export default App;
