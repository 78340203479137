import React, { Component } from 'react';
import Isvg from 'react-inlinesvg';
import { Link } from 'react-router-dom';

import {
    Container,
    Row,
    Col,
    Dropdown,
    DropdownItem,
    DropdownMenu,
    DropdownToggle
} from 'reactstrap';

import phone from '../assets/phone.svg';
import fb from '../assets/fb.svg';
import instagram from '../assets/instagram.svg';

import pin from '../assets/pin.png';




const routes = [
    {
        name: 'ACCUEIL',
        link: ''
    },
    {
        name: 'QUI SUIS-JE',
        link: '#qui-suis-je'
    },
    {
        name: 'QUE FAIT-ON CONCRETEMENT ?',
        link: '#que-fait-on-concretement'
    },
    {
        name: 'TEMOIGNAGES',
        link: '#temoignages'
    },
    {
        name: 'TARIFS ET PRESTATIONS',
        link: '#tarifs-et-prestations'
    },
    {
        name: 'CONTACT',
        link: '#contact'
    },
]

export default class Footer extends Component {
    constructor(props) {
        super(props);

        this.state = {

        };
    }


    initMap() {
        //console.log("InitMAP");
        this.setState({
            _mapInit: true
        });
        var latLng = new window.google.maps.LatLng( this.props.data.gps.split(',')[0], this.props.data.gps.split(',')[1]


            );

        var map = new window.google.maps.Map(this.GoogleMap, {
            zoom: 16,
            center: latLng,
            mapTypeId: window.google.maps.MapTypeId.ROADMAP,
            disableDefaultUI: true,
            gestureHandling: "gestureHandling",
        });


        var marker = new window.google.maps.Marker({
            position: latLng,
            map: map,
            icon: pin
        });

        marker.addListener('click', () => {
            var win = window.open('https://www.google.com/maps/place/Booster+Coaching/@46.4486258,6.8954574,19z/data=!3m1!4b1!4m5!3m4!1s0x478e9b558c549d89:0xef4369751144360e!8m2!3d46.4486258!4d6.8960046', '_blank');
            win.focus();          
        });
  
        this.setState({ googleMap: map });
    }

    componentDidMount() {

        if (this.props._googleMapsLoaded && !this.state._mapInit && this.props.data.gps) {
            this.initMap();
        }

    }

    componentDidUpdate(prevProps) {

        if (this.props._googleMapsLoaded && !this.state._mapInit && this.props.data.gps) {
            this.initMap();
        }
    }

    render() {
        return (
            <footer>
                <Container>
                    <Row>
                        <Col lg="12">
                            {this.props._googleMapsLoaded ?
                                <div className="map" ref={(input) => { this.GoogleMap = input; }}>

                                </div>
                                : null
                            }

                        </Col>



                        <Col lg="12">
                            <ul className="navigation">
                                {routes.map((item, idx) => {
                                    return (
                                        <li key={idx} onClick={() => this.props.enableScrollIntoView(true)}>
                                            <Link to={item.link}>
                                                {item.name}
                                            </Link>
                                        </li>

                                    )
                                })
                                }
                            </ul>
                        </Col>
                        <Col lg="10" xs="8">
                            <p className="copyright">Copyright © Booster Coaching  - 2019. All rights reserved.</p>
                        </Col>
                        <Col lg="2" xs="4">
                            <p className="created-by">Created by <a href="https://aimedia.ch" target="_blank">AImedia</a></p>
                        </Col>

                    </Row>
                </Container>
            </footer>

        )
    }
}
