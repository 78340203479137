import React, { Component } from 'react';
import { Link, Redirect } from 'react-router-dom';
import Isvg from 'react-inlinesvg';
import Page from '../containers/page';

import {
    Container,
    Row,
    Col,
    Dropdown,
    DropdownItem,
    DropdownMenu,
    DropdownToggle,
    Carousel,
    CarouselItem,
    CarouselControl,
    CarouselIndicators,
    CarouselCaption

} from 'reactstrap';


import anim1 from '../assets/first.gif';
import anim2 from '../assets/2.png';
import anim3 from '../assets/meta.gif';
import anim4 from '../assets/5.png';
import anim5 from '../assets/laptop.gif';
import leftImg from '../assets/left.png';
import rightImg from '../assets/right.png';

import scroll from '../assets/scroll.svg';

import left from '../assets/left.svg';
import right from '../assets/right.svg';
import Button from '../components/button';
import ReCAPTCHA from "react-google-recaptcha";

import Form from '../components/forms/form';

import Lottie from 'react-lottie';
import * as animationData1 from '../1.json'
import * as animationData2 from '../2.json'
import * as animationData3 from '../3.json'
import * as animationData4 from '../4.json'
import * as animationData5 from '../5.json'
import 'whatwg-fetch'




const items = [
    {
        subtitle: 'TEMOIGNAGES',
        title: 'TEMOIGNAGE 1',
        content: '« Le coaching est hyper utile, vraiment hyper-intéressant, ce qui est bien, je trouve (il y a plein de choses qui sont bien), c’est que l’on fait des exercices pratiques, ce n’est pas comme fouiller dans mon passé, non, c’est vraiment, maintenant, ce que l’on va mettre en place, et en faite, ça me fait avoir des réflexions hypersaines, hyperpositives pour aller de l’avant et ça m’aide vraiment. Je pense que ce n’est pas pour rien si je me sens si bien ces temps... »'
    },
    {
        subtitle: 'TEMOIGNAGES',
        title: 'TEMOIGNAGE 2',
        content: `« L'exercice a été hallucinant comme expérience !!! J'ai adoré. Cela me permet d'être beaucoup plus sereine, de me faire confiance pour mon futur. »`
    },
    {
        subtitle: 'TEMOIGNAGES',
        title: 'TEMOIGNAGE 3',
        content: `« Le soutien de Gaia, m'a aidé maintes fois durant mon parcours professionnel. Aux travers des exercices, j'ai pu mieux mettre en lumière les valeurs de ma profession. Cela est encore plus important quand tu ignores où tu es et dans quelle direction tu veux bouger. Les doutes sont parfois constructifs, mais savoir les éclaircir l'est encore plus. Je pense que je vais m'adresser encore à Gaia dans le futur, parce que on ne finit jamais d'apprendre, surtout sur soi-même. »`
    },
    {
        subtitle: 'TEMOIGNAGES',
        title: 'TEMOIGNAGE 4',
        content: `« Maintenant je mets de choses en place pour atteindre mes objectifs, mais sans angoisses. Je me suis senti tellement en paix lors du dernier exercice. »`
    },
];


const routes = {
    '': 0,
    '#qui-suis-je': 1,
    '#que-fait-on-concretement': 2,
    '#temoignages': 3,
    '#tarifs-et-prestations': 4,
    '#contact': 5
}
const _routes = [
    '',
    '#qui-suis-je',
    '#que-fait-on-concretement',
    '#temoignages',
    '#tarifs-et-prestations',
    '#contact'
]

const sleep = ms => new Promise(resolve => setTimeout(resolve, ms))

//site key
//6Ld2TrcUAAAAAK6kDP_6yfWTiR4iRnpsP7iEXuMC

//secret key
//6Ld2TrcUAAAAALfqnwx3jd74A77tOqPhLKyzjel_

class HomePage extends Component {
    constructor(props) {
        super(props);

        this.state = { activeIndex: 0, yScroll: 0 };
        this.next = this.next.bind(this);
        this.previous = this.previous.bind(this);
        this.goToIndex = this.goToIndex.bind(this);
        this.onExiting = this.onExiting.bind(this);
        this.onExited = this.onExited.bind(this);
        this.scrollTo = this.scrollTo.bind(this);
        this.contact = this.contact.bind(this);

        this.nodes = [null, null, null, null, null, null];

    }

    onExiting() {
        this.animating = true;
    }

    onExited() {
        this.animating = false;
    }

    next() {
        if (this.animating) return;
        const nextIndex = this.state.activeIndex === items.length - 1 ? 0 : this.state.activeIndex + 1;
        this.setState({ activeIndex: nextIndex });
    }

    previous() {
        if (this.animating) return;
        const nextIndex = this.state.activeIndex === 0 ? items.length - 1 : this.state.activeIndex - 1;
        this.setState({ activeIndex: nextIndex });
    }

    goToIndex(newIndex) {
        if (this.animating) return;
        this.setState({ activeIndex: newIndex });
    }




    componentDidMount() {
        window.addEventListener('scroll', this.listenToScroll);
        window.addEventListener("touchmove", this.listenToScroll, false);

        if (this.props[0].location.hash !== '') {
            setTimeout(
                this.scrollTo, 500)

        }

    }

    componentWillUnmount() {
        window.removeEventListener('scroll', this.listenToScroll)
        window.removeEventListener('touchmove', this.listenToScroll)

    }

    listenToScroll = () => {
        const yScroll =
            document.body.scrollTop || document.documentElement.scrollTop


        if (this.state._scrolling) {
            return;
        }

        let dirrection;
        if (yScroll > this.state.yScroll) {
            dirrection = 0;
        } else {
            dirrection = 1;
        }

        //if (dirrection == 0) {
        for (let i = this.nodes.length - 1; i >= 0; i--) {
            if (yScroll > this.nodes[i].offsetTop - (window.innerWidth > 1023 ? 160 : 110) && yScroll < this.nodes[i].offsetTop + this.nodes[i].clientHeight - (window.innerWidth > 1023 ? 160 : 110)) {
                if (_routes[i] != this.props[0].location.hash) {
                    this.props.enableScrollIntoView(null, () => {
                        console.log('push history')
                        this.props[0].history.push(_routes[i]);

                    });
                }


                break;
            }
        }
        /*} else {
            for (let i = 0; i < this.nodes.length; i++) {
                

                if (yScroll > this.nodes[i].offsetTop + (window.innerWidth > 1023 ? 160 : 110)) {

                    this.props.enableScrollIntoView(null, () => {
                        this.props[0].history.push(_routes[i]);

                    });


                    break;
                }
            }

        }*/

        this.setState({
            yScroll
        })
    }


    componentDidUpdate(prevProps) {
        //console.log(this.props._enableScrollIntoView);
        //console.log(prevProps[0]);
        if (prevProps[0].location.hash !== this.props[0].location.hash && this.props._enableScrollIntoView) {
            this.setState({
                _scrolled: null
            }, () => {
                this.scrollTo();
            })
        }

    }


    async scrollTo() {
        /*if (this.state._scrolled){
            return;
        }*/
        //console.log('aaaa');
        this.setState({
            _scrolled: true
        })


        let pathname = this.props[0].location.hash;
        //console.log(pathname);
        if (routes[pathname] === null) {
            return;
        }
        //console.log(routes[pathname], typeof (routes[pathname]));
        //console.log(this.nodes);
        //console.log(this.nodes[routes[pathname]]);
        let node = this.nodes[routes[pathname]];
        if (!node) {
            return;
        }



        //console.log(node.offsetTop);


        window.scrollTo({
            top: node.offsetTop - (window.innerWidth > 1023 ? 120 : 110),
            left: 0,
            behavior: 'smooth'
        })

        this.setState({
            _scrolling: true
        }, () => {
            setTimeout(() => {
                this.setState({
                    _scrolling: null
                });
            }, 1000)
        })


        /*
                if (routes[pathname] == 0){
                    window.scrollTo({
                        top: 0,
                        left: 0,
                        behavior: 'smooth'
                    })
                }else{
                    node.scrollIntoView({behavior: 'smooth'});
                }*/
    }


    contact(data) {
        //console.log(data);

        fetch('https://api.aimedia.ch/contact', {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
            },
            body: JSON.stringify(data)
        }).then((res) => res.json()).then((result) => {
            //console.log(result);
            if (result.successful) {
                this.setState({
                    _contactDone: true
                })
            }
        })

    }
    render() {
        ////console.log(this.nodes);
        const { activeIndex } = this.state;

        const slides = this.props.reviews.map((item) => {
            return (
                <CarouselItem
                    onExiting={this.onExiting}
                    onExited={this.onExited}
                    key={item.title}
                >
                    <h6>TEMOIGNAGES</h6>
                    <h3>{item.name}</h3>
                    <p dangerouslySetInnerHTML={{ __html: item.content }}></p>
                </CarouselItem>
            );
        });

        return (
            <div className="page-wrap" >

                <section className="section-1" ref={(node) => this.nodes[0] = node}>
                    <Container>

                        <Row>
                            <Col lg={{ size: 6, order: 1 }} md={{ size: 6, order: 0 }} xs={{ size: 12, order: 1 }} dangerouslySetInnerHTML={{ __html: this.props.pages[0] && this.props.pages[0].content }}>
                            </Col>
                            <Col lg={{ size: 6, order: 1 }} md={{ size: 6, order: 1 }} xs={{ size: 12, order: 0 }}>
                                {/*<div className="animation-container">
                                    <Lottie options={{
                                        loop: true,
                                        autoplay: true,
                                        animationData: animationData1,
                                        rendererSettings: {
                                            preserveAspectRatio: 'xMidYMid slice'
                                        }
                                    }}></Lottie>
                                </div>*/}
                                <img src={anim1} />

                            </Col>
                            <Col lg={{ size: 12, order: 2 }} className="scroll-down" md={{ size: 12, order: 2 }} xs={{ size: 12, order: 2 }} onClick={() => this.props.enableScrollIntoView(true)}>
                                <Link to='#qui-suis-je'>

                                    <Isvg src={scroll} />
                                    <span>DÉFILEMENT EN BAS</span>
                                </Link>

                            </Col>
                        </Row>


                    </Container>
                </section>


                <section className="section-2" ref={(node) => this.nodes[1] = node}>
                    <Container>

                        <Row>
                            <Col lg={{ size: 6, order: 0 }} md={{ size: 6, order: 0 }} xs={{ size: 12, order: 1 }}>
                                {/*<div className="animation-container">
                                   <Lottie options={{
                                        loop: true,
                                        autoplay: true,
                                        animationData: animationData2,
                                        rendererSettings: {
                                            preserveAspectRatio: 'xMidYMid slice'
                                        }
                                    }}></Lottie>
                                
                                </div>
                                */}
                                <img src={anim2} />

                            </Col>

                            <Col lg={{ size: 6, order: 1 }} md={{ size: 6, order: 1 }} xs={{ size: 12, order: 0 }} dangerouslySetInnerHTML={{ __html: this.props.pages[1] && this.props.pages[1].content }}>
                            </Col>
                        </Row>
                        <div ref={(node) => this.nodes[2] = node}>

                            <Row >

                                <Col lg={{ size: 6, order: 2 }} md={{ size: 6, order: 2 }} xs={{ size: 12, order: 2 }} dangerouslySetInnerHTML={{ __html: this.props.pages[2] && this.props.pages[2].content }}>
                                </Col>
                                <Col lg={{ size: 6, order: 3 }} md={{ size: 6, order: 3 }} xs={{ size: 12, order: 3 }}>
                                    {/* <div className="animation-container">
                                        <Lottie options={{
                                            loop: true,
                                            autoplay: true,
                                            animationData: animationData3,
                                            rendererSettings: {
                                                preserveAspectRatio: 'xMidYMid slice'
                                            }
                                        }}></Lottie>
                                    </div>*/}
                                    <img src={anim3} />
                                </Col>


                            </Row>
                        </div>


                    </Container>
                </section>


                <section className="section-3">
                    <Container>

                        <Row dangerouslySetInnerHTML={{ __html: this.props.pages[2] && this.props.pages[2].bottomContent }}>


                        </Row>


                    </Container>
                </section>
                <section className="section-4" ref={(node) => this.nodes[3] = node}>
                    <img src={leftImg} className="bg" />
                    <Container>

                        <Row>


                            <Col lg="4" md="4" xs="12">
                                {/* <div className="animation-container">
                                    <Lottie options={{
                                        loop: true,
                                        autoplay: true,
                                        animationData: animationData5,
                                        rendererSettings: {
                                            preserveAspectRatio: 'xMidYMid slice'
                                        }
                                    }}></Lottie>
                                </div>*/}
                                <img src={anim4} className="image" />

                            </Col>
                            <Col lg={{ offset: 1, size: 6 }} md="7" xs="12">
                                <Carousel
                                    activeIndex={activeIndex}
                                    next={this.next}
                                    previous={this.previous}
                                    interval={5000}
                                >
                                    {slides}
                                </Carousel>

                                <button onClick={this.previous}>
                                    <Isvg src={left} />
                                </button>
                                <button onClick={this.next}>
                                    <Isvg src={right} />
                                </button>

                            </Col>

                        </Row>


                    </Container>
                </section>

                <section className="section-5" ref={(node) => this.nodes[4] = node}>
                    <Container>

                        <Row>

                            <Col lg="6" md={{ size: 6 }} xs="12">
                                <div dangerouslySetInnerHTML={{ __html: this.props.pages[3] && this.props.pages[3].content }}>
                                </div>
                                <Link onClick={() => this.props.enableScrollIntoView(true)} to='#contact'> <Button>J’APPELLE POUR EN SAVOIR PLUS</Button></Link>

                            </Col>
                            <Col lg="6" md={{ size: 6 }} xs="12">
                                {/*<div className="animation-container">
                                    <Lottie options={{
                                        loop: true,
                                        autoplay: true,
                                        animationData: animationData4,
                                        rendererSettings: {
                                            preserveAspectRatio: 'xMidYMid slice'
                                        }
                                    }}></Lottie>
                                
                                
                                </div>*/}
                                <img src={anim5} />
                            </Col>


                        </Row>


                    </Container>
                </section>
                <section className="section-6" ref={(node) => this.nodes[5] = node}>
                    <img src={rightImg} className="bg" />
                    <Container>

                        <Row>


                            <Col lg="4" md={{ size: 4 }}>
                                <h3>PRENDRE RENDEZ-VOUS</h3>
                                <h6>Adresse:</h6>
                                <p dangerouslySetInnerHTML={{ __html: this.props.data.address && this.props.data.address.replace(/\n/g, '<br/>') }}>

                                </p>
                                <h6>E-mail:</h6>
                                <p><a href={`mailto://${this.props.data.email}`}>{this.props.data.email}</a></p>
                                <h6>Téléphone:</h6>
                                <p><a href={`tel://${this.props.data.phone}`}>{this.props.data.phone}</a></p>

                            </Col>
                            <Col lg={{ offset: 2, size: 6 }} md={{ size: 8 }}>
                                <Container>
                                    <Row>
                                        <Col lg="12">
                                            <h2>CONTACTEZ-NOUS</h2>
                                        </Col>
                                    </Row>

                                    <Form onSubmit={this.contact}></Form>
                                    {this.state._contactDone ? <Row>
                                        <Col lg="12">
                                            <p>Votre message a bien été transmis</p>
                                        </Col>
                                    </Row>
                                        : null
                                    }

                                </Container>
                            </Col>

                        </Row>


                    </Container>
                </section>



            </div >
        );
    }
}

export default Page(HomePage);