import React, { Component } from 'react';
import Isvg from 'react-inlinesvg';
import { Link } from 'react-router-dom';

import {
    Container,
    Row,
    Col,
    Dropdown,
    DropdownItem,
    DropdownMenu,
    DropdownToggle
} from 'reactstrap';

import phone from '../assets/phone.svg';
import fb from '../assets/fb.svg';
import linkedin from '../assets/linkedin.svg';

import logo from '../assets/logo.svg';
import menu from '../assets/menu.svg';
import close from '../assets/close.svg';


const routes = [
    {
        name: 'ACCUEIL',
        link: ''
    },
    {
        name: 'QUI SUIS-JE',
        link: '#qui-suis-je'
    },
    {
        name: 'QUE FAIT-ON CONCRETEMENT ?',
        link: '#que-fait-on-concretement'
    },
    {
        name: 'TEMOIGNAGES',
        link: '#temoignages'
    },
    {
        name: 'TARIFS ET PRESTATIONS',
        link: '#tarifs-et-prestations'
    },
    {
        name: 'CONTACT',
        link: '#contact'
    },
]
const _routes = {
    '': 0,
    '#qui-suis-je': 1,
    '#que-fait-on-concretement': 2,
    '#temoignages': 3,
    '#tarifs-et-prestations': 4,
    '#contact': 5
}
const sleep = ms => new Promise(resolve => setTimeout(resolve, ms))

export default class Header extends Component {
    constructor(props) {
        super(props);
        
        this.nodes = [];

        this.state = {
            menu: null,
            dotPos: null
        }
    }
    componentDidMount() {
        window.addEventListener('scroll', this.listenToScroll);

        let nextIdx = _routes[this.props[0].location.hash];
        this.setState({
            dotPos: this.nodes[nextIdx].offsetLeft + this.nodes[nextIdx].offsetWidth / 2 - 4
        })
    }

    componentWillUnmount() {
        window.removeEventListener('scroll', this.listenToScroll)
    }

    listenToScroll = () => {
        const yScroll =
            document.body.scrollTop || document.documentElement.scrollTop


        this.setState({
            yScroll
        })
    }

    async componentDidUpdate(prevProps) {
        if (prevProps[0].location.hash != this.props[0].location.hash) {
            this.setState({
                menu: null
            })


            let prevIdx = _routes[prevProps[0].location.hash];
            let nextIdx = _routes[this.props[0].location.hash];
            //console.log(prevIdx, nextIdx);
    
            //console.log(this.nodes[prevIdx].offsetLeft, this.nodes[prevIdx].offsetWidth);
    
    
            let startPos = this.nodes[prevIdx].offsetLeft + this.nodes[prevIdx].offsetWidth / 2 - 4;
            let endPos = this.nodes[nextIdx].offsetLeft + this.nodes[nextIdx].offsetWidth / 2 - 4;
            //console.log(startPos, endPos);
            if (prevIdx > nextIdx){
                for(let i=startPos;i>=endPos;i-=20){
                    this.setState({
                        dotPos: i
                    })
                    await sleep(10);
                }
            }else{
                for(let i=startPos;i<=endPos;i+=20){
                    this.setState({
                        dotPos: i
                    })

                    await sleep(10);
                }
    
            }

            this.setState({
                dotPos: endPos
            })

    
        }


    }

    render() {
        return (
            <header className={this.state.yScroll >= 100 ? 'sticky' : null}>
                <div className="header">
                    <Container>
                        <Row>
                            <Col lg="4" className="telephone">
                                <a href={`tel://${this.props.data.phone}`} target="_blank"><Isvg src={phone} /></a>
                                <span className="phone">{this.props.data.phone}</span>
                            </Col>
                            <Col lg={{ size: 4 }} xs={{ size: 8 }} className="logo">
                                <Isvg src={logo} />
                            </Col>
                            <Col lg={{ size: 4 }} className="social">
                                <span className="text">Suivez-moi</span>
                                <a href="https://www.facebook.com/Booster-Coaching-113607986661962/?ref=aymt_homepage_panel&eid=ARDSVv6w-SLeRXrsZwrig2sEzu_w36_Pwxozt0IEdY03SEUjxz04ncUTcQ8jQl5KIef3ZxMqYBfPbpI5" target="_blank"><Isvg src={fb} /></a>
                                <a href="https://www.linkedin.com/in/gaia-poggi-72780760/" target="_blank"><Isvg src={linkedin} /></a>
                            </Col>

                            <Col xs={{ size: 4 }} className="menu">
                                <button onClick={() => this.setState({ menu: true })}>
                                    <Isvg src={menu} />
                                </button>
                            </Col>

                        </Row>
                    </Container>
                </div>
                <Container>
                    <Row>
                        <Col lg="12" className="navigation-container">
                        
                            <ul className={this.state.menu ? `navigation navigation-mobile` : `navigation`}>
                                {routes.map((item, idx) => {
                                    return (
                                        <li ref={(node) => this.nodes[idx] = node} key={idx} onClick={() => this.props.enableScrollIntoView(true)} className={this.props[0].location.hash == item.link ? 'active' : null}>
                                            <Link to={item.link}>
                                                {item.name}
                                            </Link>
                                        </li>

                                    )
                                })
                                }
                            </ul>


                            <div className="dot" style={{left: this.state.dotPos + 'px'}}></div>

                            {this.state.menu ?
                                <button className="mobile-close" onClick={() => this.setState({ menu: null })}>
                                    <Isvg src={close} />

                                </button>
                                :
                                null
                            }

                            {this.state.menu ?
                                <div className="mobile-social">
                                    <a href={`tel://${this.props.data.phone}`} target="_blank"><Isvg src={phone} /></a>
                                    <a href="https://www.facebook.com/Booster-Coaching-113607986661962/?ref=aymt_homepage_panel&eid=ARDSVv6w-SLeRXrsZwrig2sEzu_w36_Pwxozt0IEdY03SEUjxz04ncUTcQ8jQl5KIef3ZxMqYBfPbpI5" target="_blank"><Isvg src={fb} /></a>
                                    <a href="https://www.linkedin.com/in/gaia-poggi-72780760/" target="_blank"><Isvg src={linkedin} /></a>

                                </div>
                                :
                                null
                            }

                        </Col>
                    </Row>
                </Container>

            </header>
        )
    }
}
